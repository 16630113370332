import { defaults, LoginSession, detectEnvironment } from '@iplabs/user-management-sdk';
import { clearRedirectUri, persistInitialTargetUri, readAndClearRedirectUri } from '../redirects';
import { AuthStore } from './AuthStore';
import { sharedParams } from '../SharedParams';

/**
 * The login process redirects the user to a cognito login page
 * from which they are again redirected to the `/login` path on
 * successful login.
 * In case the user is accessing myportfolio using a deep-link,
 * the deep-link would be "forgotten". Because of this, we store
 * the initial path and search query and redirect to that path
 * after the login finished.
 */
const redirect = readAndClearRedirectUri();
if (!redirect) {
    persistInitialTargetUri();
}

export const session = new LoginSession({
    signInRedirectUri: window.location.origin + '/login',
    signOutRedirectUri: window.location.origin + '/login',
    cookieDomain: `.${window.location.hostname}`,
    clientId: defaults[detectEnvironment()].cognitoClientId,
    appWebDomain: defaults[detectEnvironment()].appWebDomain,
});
export const authStore = new AuthStore();

/**
 * Whenever a new access token is available (either through login
 * or token refresh), we get notified and set the new token into the
 * `AuthStore`. This store is passed down to all applications.
 */
session.onAccessToken = (accessToken: string): void => {
    sharedParams.set('root.auth.accessToken', accessToken);
    authStore.setToken(accessToken);

    if (window.location.pathname === '/login') {
        if (redirect) {
            // go to originally requested url (deep-linking)
            clearRedirectUri();
            window.location.href = window.origin + redirect;
        } else {
            // remove the /login portion
            window.location.href = window.origin;
        }
    }
};

/**
 * Start the login process.
 */
session.login();